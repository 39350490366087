import './how-to-buy.scss'
import arrow from "../../assets/images/how-to-buy/arrow.png"
import createwallet from "../../assets/images/how-to-buy/create-wallet.png"
import sol from "../../assets/images/how-to-buy/sol.png"
import connectwallet from "../../assets/images/how-to-buy/connect-wallet.png"
import ear from "../../assets/images/how-to-buy/ear.png"
import dots from "../../assets/images/how-to-buy/dots.png"
import boom from "../../assets/images/how-to-buy/boom.png"
import {ReactComponent as CopyIcon} from '../../assets/icons/how-to-buy/copy.svg'
import {useState} from "react";
import toast from "react-hot-toast";

export const HowToBuy = () => {

    const [address, setAddress] = useState("coming soon")

    function copyToClipboard(value, name) {
        navigator.clipboard.writeText(value || "");
        toast.success(`${name} copied to clipboard.`);
    }

    return (
        <div className="cbc-how-to-buy">
            <img className="cbc-dots" src={dots} alt="Dots"/>
            <img src={boom} alt={"Boom"} className={"cbc-boom"} />
            <div className="title">
                <img src={arrow} alt={"Arrow"}/>
                <h3>HOW TO BUY</h3>
            </div>
            <ul>
                <li>
                    <img src={createwallet} alt="CreateWallet"/>
                    <div className="column">
                        <h4>1- Create a Wallet</h4>
                        <p>
                            Download Phantom from the app store or google play store for free. Desktop users, download
                            the Phantom Google Chrome extension.
                        </p>
                    </div>
                </li>
                <li>
                    <div className="column">
                        <h4>2- Get some SOL</h4>
                        <p>
                            Have $SOL in your wallet to switch to $CBOC. If you dont have $SOL in your wallet you can buy directly on your wallet, transfer from another wallet, or buy on another exchange and transfer to your wallet.
                        </p>
                    </div>
                    <img src={sol} alt="Solana"/>
                </li>
                <li>
                    <img src={connectwallet} alt="ConnectWallet"/>
                    <div className="column">
                        <h4>3- Connect a Wallet</h4>
                        <p>
                            Connect to Raydium or Jupiter and in Google Chrome or on the browser inside your Phantom
                            app. Connect your wallet. Paste the CAT token token address into search, select CAT and
                            confirm. When your wallet prompts you for a wallet signature, sign.
                        </p>
                    </div>
                </li>
                <li>
                    <div className="column">
                        <h4>4- Central of Cats Token Address</h4>
                        <div className="cbc-address">
                            <span>{address}</span>
                            <CopyIcon onClick={() => copyToClipboard(address, "CBOC Token Address")}/>
                        </div>
                    </div>
                    <img src={ear} alt="Ear" className="desktop"/>
                </li>
            </ul>
        </div>
    )
}
