import './frequently-asked-questions.scss'
import lamp from "../../assets/images/frequently-asked-questions/lamp.png";
import {Collapse} from "antd";
import {ReactComponent as Paw1Icon} from "../../assets/icons/frequently-asked-questions/pang.svg"
import dots from "../../assets/images/frequently-asked-questions/dots.png"
import dotsmobile from "../../assets/images/frequently-asked-questions/dots-mobile.png"
import crackright from "../../assets/images/frequently-asked-questions/crack-right.png"
import deadcat from "../../assets/images/how-to-buy/dead-cat-v2.png"

const FAQ_ITEMS = [
    {
        key: '1',
        label: '1- What is $CBOC?',
        children: <p>- $CBOC is humanities last chance of survival - amidst global uncertainty and difficulties the almighty jo boden dolla' currently goes through the deep state came with a solution.. the $CBOC digital currency backed by on-chain degeneracy. </p>,
    },
    {
        key: '2',
        label: '2- How do I buy a VPN server?',
        children: <p>- You can purchase private VPN's through our automated blockchain powered TG Bot - Users can pay in $CBOC $SOL $USDC $WIF $BONK & Many more which will be implemented down the road! </p>,
    },
    {
        key: '3',
        label: '3- Is $CBOC affiliated with any governmental institutions?',
        children: <p>- No $CBOC is a pure meme-coin launched by degens for the degens - The Central Bank Of Cats is a pure animated satirycal protrayal of US's economic system. </p>,
    },
    {
        key: '4',
        label: '4- Where can I find more info?',
        children: <p>- Follow us on X and join our TG Channel for more information.</p>,
    }
];

export const FrequentlyAskedQuestions = () => {
    return (
        <div className="cbc-frequently-asked-questions">
            <img src={dots} alt={"Dots"} className={"cbc-dots"}/>
            <img src={dotsmobile} alt={"DotsMobile"} className={"cbc-dots-mobile"}/>
            <img src={crackright} alt={"CrackRight"} className={"cbc-crack-right"}/>
            <img src={deadcat} alt={"DeadCat"} className={"cbc-deadcat"}/>
            <div className="title">
                <img src={lamp} alt={"Lamp"}/>
                <h3>Frequently <br className="mobile"/>Asked Questions</h3>
            </div>
            <Collapse items={FAQ_ITEMS} expandIconPosition='end'
                      expandIcon={({isActive}) => <Paw1Icon style={{transform: `rotate(${isActive ? 180 : 0}deg)`}}/>}/>
        </div>
    )
}
